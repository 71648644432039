<template>
  <div class="tableDetailNews">
    <Breadcrumb :title="BreadcrumbTitle" />
    <div class="tableDetailNews__content">
      <div v-if="typeNews==='searChsan'">
        <el-tabs type="border-card" tab-position="left"  @tab-click="handleTabClick">
          <el-tab-pane > 
            <span slot="label" ><font-awesome-icon icon="rss" style="margin: 0px 10px;"></font-awesome-icon>制裁名單類別</span> 
            <!-- 制裁名單類別 -->
            <el-row class="tableDetailNews__content--SanList mt-8">
              <el-col v-for="item in SanList" :key="item.ListID">
                <table style="width: 100%;" max-height="400">
                  <tbody>
                    <tr>
                      <th class="tableHead" colspan="1">序號</th>
                      <td class="tableBody" colspan="7">{{ item.DataID }}</td>
                    </tr>
                    <tr>
                      <th class="tableHead">來源名稱</th>
                      <td class="tableBody">{{ item.SourceName }}</td>
                       <th class="tableHead">名單分類</th>
                      <td class="tableBody">{{ item.ListType }}</td>
                      <th class="tableHead">名單編號</th>
                      <td class="tableBody">{{ item.ListID }}</td>
                      <th class="tableHead">版本編號</th>
                      <td class="tableBody">{{ item.VersionNm }}</td>
                    </tr>
                    <tr>
                      <th class="tableHead">第一個名字</th>
                      <td class="tableBody">{{ item.FirstName }}</td>
                      <th class="tableHead">第二個名字</th>
                      <td class="tableBody">{{ item.SecondName }}</td>
                      <th class="tableHead">第三個名字</th>
                      <td class="tableBody">{{ item.ThirdName }}</td>
                      <th class="tableHead">第四個名字</th>
                      <td class="tableBody">{{ item.FourthName }}</td>
                    </tr>
                    <tr>
                      <th class="tableHead">聯合國名單種類</th>
                      <td class="tableBody">{{ item.UNListType }}</td>
                      <th class="tableHead">參考編號</th>
                      <td class="tableBody">{{ item.ReferenceNm }}</td>
                      <th class="tableHead">發布時間</th>
                      <td class="tableBody">{{ item.PublicDt }}</td>
                      <th class="tableHead"></th>
                      <td class="tableBody"></td>
                    </tr>
                    <tr>
                      <th class="tableHead">原始名稱</th>
                      <td class="tableBody">{{ item.OriginalName }}</td>
                      <th class="tableHead">性別</th>
                      <td class="tableBody">{{ item.Gender }}</td>
                      <th class="tableHead">提交者</th>
                      <td class="tableBody">{{ item.Submit }}</td>
                      <th class="tableHead">資料日期</th>
                      <td class="tableBody">{{ item.DataDt }}</td>
                    </tr>
                    <tr>
                      <th class="tableHead" colspan="1">註釋</th>
                      <td class="tableBody" colspan="7">{{ item.Comment }}</td>
                    </tr>
                  </tbody>
                </table>
              </el-col>
            </el-row>
          </el-tab-pane>

          <el-tab-pane > 
            <span slot="label" ><font-awesome-icon icon="rss" style="margin: 0px 10px;"></font-awesome-icon>制裁名單住址</span> 
            <!-- 制裁名單住址 -->
            <div class="mt-8">                
                <el-table border style="width: 100%" max-height="400" :data="tableData.SanList_Address"  :header-cell-style="tableHeaderStyle">
                  
                  <el-table-column prop="ListID" label="名單編號"> </el-table-column>
                  <el-table-column prop="Street" label="街道"> </el-table-column>
                  <el-table-column prop="City" label="城市"> </el-table-column>
                  <el-table-column prop="ZipCode" label="郵區編號"> </el-table-column>
                  <el-table-column prop="Country" label="國家"> </el-table-column>
                  <el-table-column prop="StateProvince" label="州省"></el-table-column>
                  <el-table-column prop="Memo" label="註釋"> </el-table-column>
                  <el-table-column prop="DataDt" label="資料日期">
                  </el-table-column>
                </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane > 
            <span slot="label" ><font-awesome-icon icon="rss" style="margin: 0px 10px;"></font-awesome-icon>制裁名單別名</span>
            <!-- 制裁名單別名 -->
            <div class="mt-8">

                <el-table border :data="tableData.SanList_Alias" max-height="400" style="width: 100%"  :header-cell-style="tableHeaderStyle">

                  <el-table-column prop="ListID" label="名單編號"></el-table-column>
                  <el-table-column prop="Quality" label="別名品質"></el-table-column>
                  <el-table-column prop="AliasName" label="別名名稱"></el-table-column>
                  <el-table-column prop="BornDt" label="出生日期"></el-table-column>
                  <el-table-column prop="BornCity" label="出生城市"></el-table-column>
                  <el-table-column prop="BornCountry" label="出生國家"></el-table-column>
                  <el-table-column prop="Memo" label="註釋"></el-table-column>
                  <el-table-column prop="DataDt" label="資料日期"></el-table-column> 
                </el-table>

            </div>
          </el-tab-pane>

          <el-tab-pane > 
            <span slot="label" ><font-awesome-icon icon="rss" style="margin: 0px 10px;"></font-awesome-icon>制裁名單生日</span>
            <!-- 制裁名單生日 -->
            <div class="mt-8">
                <el-table border :data="tableData.SanList_Birth" style="width: 100%" max-height="400" :header-cell-style="tableHeaderStyle">
                  <el-table-column prop="BornDt" label="出生日期"></el-table-column>
                  <el-table-column prop="BornEnd" label="出生區間_迄"></el-table-column>
                  <el-table-column prop="BornStart" label="出生區間_起"></el-table-column>
                  <el-table-column prop="BornYear" label="出生年份"></el-table-column>                  
                  <el-table-column prop="DtType" label="日期種類"></el-table-column>
                  <el-table-column prop="ListID" label="名單編號"></el-table-column>
                  <el-table-column prop="Memo" label="註釋"></el-table-column>
                  <el-table-column prop="DataDt" label="資料日期" ></el-table-column> 
                </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane >
            <span slot="label" ><font-awesome-icon icon="rss" style="margin: 0px 10px;"></font-awesome-icon>制裁名單稱號</span> 
            <!-- 制裁名單稱號 -->
            <div class="mt-8">
              
                <el-table border style="width: 90%" max-height="400"   :data="tableData.SanList_Designation"   :header-cell-style="tableHeaderStyle">
                  <el-table-column prop="ListID" label="名單編號" min-width="20%"></el-table-column>
                  <el-table-column prop="Designation" label="名單稱號"></el-table-column>
                  <el-table-column prop="DataDt" label="資料日期" min-width="15%" ></el-table-column> 
                </el-table>

            </div>
          </el-tab-pane>

          <el-tab-pane > 
            <span slot="label" ><font-awesome-icon icon="rss" style="margin: 0px 10px;"></font-awesome-icon>制裁名單文件</span>
            <!-- 制裁名單文件 -->
            <div class="mt-8">
                <el-table  border style="width: 100%" max-height="400" :data="tableData.SanList_Document"  :header-cell-style="tableHeaderStyle">
                  <el-table-column prop="ListID" label="名單編號"></el-table-column>
                  <el-table-column prop="DocType1" label="文件種類"></el-table-column>
                  <el-table-column prop="DocType2" label="文件種類2"></el-table-column>
                  <el-table-column prop="Nm" label="號碼"></el-table-column>
                  <el-table-column prop="IssueDt" label="發行日期"></el-table-column>
                  <el-table-column prop="IssueCity" label="發行城市"></el-table-column>
                  <el-table-column prop="IssueCountry" label="發行國家"></el-table-column>
                  <el-table-column prop="IssuingCountry" label="正在發行國家"></el-table-column>
                  <el-table-column prop="Memo" label="註釋"></el-table-column>
                  <el-table-column prop="DataDt" label="資料日期"></el-table-column>
                </el-table>

            </div>
          </el-tab-pane>

          <el-tab-pane > 
            <span slot="label" ><font-awesome-icon icon="rss" style="margin: 0px 10px;"></font-awesome-icon>制裁名單國籍</span>
            <!-- 制裁名單國籍 -->
            <div class="mt-8">

                <el-table border style="width: 90%" max-height="400" :data="tableData.SanList_Nationality" :header-cell-style="tableHeaderStyle">
                  <el-table-column prop="ListID" label="名單編號" min-width="20%"> </el-table-column>
                  <el-table-column prop="Nationality" label="國籍"> </el-table-column>
                  <el-table-column prop="DataDt" label="資料日期" min-width="15%"> </el-table-column>
                </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane > 
            <span slot="label" ><font-awesome-icon icon="rss" style="margin: 0px 10px;"></font-awesome-icon>制裁名單出生地</span>
            <!-- 制裁名單出生地-->
            <div class="mt-8">
                <el-table border :data="tableData.SanList_Place" style="width: 90%" max-height="400" :header-cell-style="tableHeaderStyle">
                  <el-table-column prop="ListID" label="編號" min-width="20%"></el-table-column>
                  <el-table-column prop="City" label="城市 " min-width="20%"></el-table-column>
                  <el-table-column prop="Country" label="出生國家" min-width="20%"> </el-table-column>
                  <el-table-column prop="StateProvince" label="州省" min-width="20%"></el-table-column>
                  <el-table-column prop="DataDt" label="資料日期" min-width="20%"> </el-table-column>
                </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane > 
            <span slot="label" ><font-awesome-icon icon="rss" style="margin: 0px 10px;"></font-awesome-icon>制裁名單稱謂</span>
            <!-- 制裁名單稱謂：-->
            <div class="mt-8">

                <el-table border :data="tableData.SanList_Title" style="width: 90%" max-height="400" :header-cell-style="tableHeaderStyle">
                  <el-table-column prop="ListID" label="名單編號" min-width="20%"> </el-table-column>
                  <el-table-column prop="Title" label="名單稱謂"  min-width="65%"> </el-table-column>
                  <el-table-column prop="DataDt" label="資料日期" min-width="15%">
                  </el-table-column> 
                </el-table>

            </div>
          </el-tab-pane>

          <el-tab-pane > 
            <!-- 制裁名單類別-->
            <span slot="label" ><font-awesome-icon icon="rss" style="margin: 0px 10px;"></font-awesome-icon>制裁名單類別</span>
            <div class="mt-8">

                <el-table border :data="tableData.SanList_Type" style="width: 90%" max-height="400" :header-cell-style="tableHeaderStyle">
                  <el-table-column prop="ListID" label="名單編號"  min-width="20%"></el-table-column>
                  <el-table-column prop="ListType" label="名單分類"  min-width="65%"></el-table-column>
                  <el-table-column prop="DataDt" label="資料日期"  min-width="15%"> </el-table-column>
                </el-table>

            </div>
          </el-tab-pane>

          <el-tab-pane >
            <span slot="label" ><font-awesome-icon icon="rss" style="margin: 0px 10px;"></font-awesome-icon>制裁名單更新時間</span> 
            <!-- 制裁名單更新時間&制裁名單稱謂 -->
            <div class="mt-8">

                <el-table border :data="tableData.SanList_Updated" style="width: 90%" max-height="400" :header-cell-style="tableHeaderStyle">
                  <el-table-column prop="ListID" label="名單編號"  min-width="20%"></el-table-column>
                  <el-table-column prop="LastMtDt" label="最後更新時間"  min-width="65%"></el-table-column>
                  <el-table-column prop="DataDt" label="資料日期"  min-width="15%">
                  </el-table-column> 
                </el-table>

            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- *政府拒絕往來廠商名單* -->
      <div v-if="typeNews==='searchConstr'">
        <div class="reminder3box">
          <table  class="reminder3css" >
            <tbody>
              <tr>
                <th class="tableHead" colspan="1">標案案號</th>
                <td class="tableBody" colspan="1">{{ tableData[0].CaseNm }}</td>
                <th class="tableHead" colspan="1">標案名稱</th>
                <td class="tableBody" colspan="1">{{ tableData[0].CaseName }}</td>              
              </tr>             
              <tr>
                <th class="tableHead" colspan="1">廠商代碼</th>
                <td class="tableBody" colspan="1">{{ tableData[0].UniComNm }}</td>
                <th class="tableHead" colspan="1">廠商名稱</th>
                <td class="tableBody" colspan="1">{{ tableData[0].ComName }}</td>
                <th class="tableHead" colspan="1">廠商地址</th>
                <td class="tableBody" colspan="1">{{ tableData[0].ComLoc }}</td>
                <th class="tableHead" colspan="1">廠商國別</th>
                <td class="tableBody" colspan="1">{{ tableData[0].ComCountry }}</td>
              </tr>
              <tr>
                <th class="tableHead" colspan="1">刊登機關代碼</th>
                <td class="tableBody" colspan="1">{{ tableData[0].PublicAuthNm }}</td>
                <th class="tableHead" colspan="1">刊登機關名稱</th>
                <td class="tableBody" colspan="1">{{ tableData[0].PublicAuthName }}</td>
                <th class="tableHead" colspan="1">機關地址</th>
                <td class="tableBody" colspan="1">{{ tableData[0].AuthLoc }}</td>
            
              </tr> 
              <tr>
                <th class="tableHead" colspan="1">聯絡人</th>
                <td class="tableBody" colspan="1">{{ tableData[0].ContactPerson }}</td>
                <th class="tableHead" colspan="1">聯絡電話</th>
                <td class="tableBody" colspan="1">{{ tableData[0].ContactPhone }}</td>
                
              </tr> 
              <tr>
                <th class="tableHead" colspan="1">符合政府採購法第101條所列之情形</th>
                <td class="tableBody" colspan="3">{{ tableData[0].GPActStatus }}</td>
                <th class="tableHead" colspan="1">原始公告日期</th>
                <td class="tableBody" colspan="1">{{ tableData[0].OrigPublicDt }}</td>
                <th class="tableHead" colspan="1">公告日期</th>
                <td class="tableBody" colspan="1">{{ tableData[0].PublicDt }}</td>
              </tr>
              <tr>
                <th class="tableHead" colspan="1">拒絕往來生效日</th>
                <td class="tableBody" colspan="1">{{ tableData[0].EffectDt }}</td>
                <th class="tableHead" colspan="1">政府採購法第103條第一項所規定之期間</th>
                <td class="tableBody" colspan="1">{{ tableData[0].GPActPeriod }}</td>
                <th class="tableHead" colspan="1">拒絕往來截止日</th>
                <td class="tableBody" colspan="1">{{ tableData[0].DeadlineDt }}</td>
              
              </tr>  
              <tr>
                <th class="tableHead" colspan="1">異議或申訴結果</th>
                <td class="tableBody" colspan="7">{{ tableData[0].AppealResult }}</td>
              </tr>                                                                                      
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="typeNews==='searchLabor'">
        <div class="reminder3box">
          <table  class="reminder3css" >
            <tbody>
              <tr>
                <th class="tableHead" colspan="1">主管機關</th>
                <td class="tableBody" colspan="1">{{ tableData[0].MgrAuth }}</td>
                <th class="tableHead" colspan="1">公告日期</th>
                <td class="tableBody" colspan="1">{{ tableData[0].PublicDt }}</td>     
                <th class="tableHead" colspan="1">處分日期</th>
                <td class="tableBody" colspan="1">{{ tableData[0].PunishDt }}</td>
                <th class="tableHead" colspan="1">處分字號</th>
                <td class="tableBody" colspan="1">{{ tableData[0].PunishNm }}</td>                            
              </tr>
              <tr>
                <th class="tableHead" colspan="1">事業單位名稱</th>
                <td class="tableBody" colspan="3">{{ tableData[0].UnitEntity }}</td>
                <th class="tableHead" colspan="1">負責人 / 自然人姓名</th>
                <td class="tableBody" colspan="3">{{ tableData[0].NameEntity }}</td>  
              </tr>
              <tr>
                <th class="tableHead" colspan="1">違法法規法條</th>
                <td class="tableBody" colspan="3">{{ tableData[0].VioLaw }}</td>
                <th class="tableHead" colspan="1">違反法規內容</th>
                <td class="tableBody" colspan="3">{{ tableData[0].VioCont }}</td>                            
              </tr>     
              <tr>
                <th class="tableHead" colspan="1">罰鍰金額</th>
                <td class="tableBody" colspan="1">{{ tableData[0].FineAmount }}</td>
                <th class="tableHead" colspan="1">備註說明</th>
                <td class="tableBody" colspan="5">{{ tableData[0].Memo }}</td>    
                     
              </tr>                        
            </tbody>   
          </table>
        </div>
      </div>
      <div v-if="typeNews==='searchEnviroment'">
        <div class="reminder3box">
          <table  class="reminder3css" >
            <tbody>
              <tr>
                <th class="tableHead" colspan="1">行為人名稱</th>
                <td class="tableBody" colspan="1">{{ tableData[0].UnitName }}</td>
                <th class="tableHead" colspan="1">名單名稱</th>
                <td class="tableBody" colspan="3">{{ tableData[0].CaseName }}</td>                
                <th class="tableHead" colspan="1">違反處分時間</th>
                <td class="tableBody" colspan="1">{{ tableData[0].PunishTime }}</td>
              </tr>
              <tr>
                <th class="tableHead" colspan="1">違反事實</th>
                <td class="tableBody" colspan="1">{{ tableData[0].VioFact }}</td> 
                <th class="tableHead" colspan="1">違反法令</th>
                <td class="tableBody" colspan="5">{{ tableData[0].VioLaw }}</td>                 
              </tr>
              <tr>
                <th class="tableHead" colspan="1">是否訴願</th>
                <td class="tableBody" colspan="1">{{ tableData[0].IsAppeal }}</td> 
                <th class="tableHead" colspan="1">訴願結果</th>
                <td class="tableBody" colspan="5">{{ tableData[0].AppealResult }}</td>                 
              </tr> 
              <tr>
                <th class="tableHead" colspan="1">限制時間</th>
                <td class="tableBody" colspan="1">{{ tableData[0].RestrictTime }}</td> 
                <th class="tableHead" colspan="1">是否已改善</th>
                <td class="tableBody" colspan="1">{{ tableData[0].IsImprove }}</td>                 
              </tr>                             
            </tbody>            
          </table>
        </div>
      </div>
    </div>
    <div class="tableDetailNews__button">
      <el-button v-if="isOperator" type="primary" @click="toggleDialog">
        <font-awesome-icon icon="plus" style="margin-right: 5px"/>建立警示名單
      </el-button>
      <el-button type="info" @click="closeDetail()">關閉</el-button>
    </div>
    <div v-if="openPopUp" class="WarningListPopUp">
      <div class="popup">
        <i class="el-icon-close" @click="toggleDialog"></i>
        <p>請選擇要新增的名單類別</p>
        <div>
          <el-button type="danger" @click="routerPush('people')">人名</el-button>
          <el-button type="danger" @click="routerPush('org')">組織</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../src/components/Breadcrumb.vue";
export default {
  components: { Breadcrumb },
  data() {
    return {
      openPopUp: false,
      userRole: this.$store.state.userInfo.Role,
      tableData: [],
      SanList: [],
      typeNews:this.$route.query.typeNews,
      dataId:this.$route.query.DataID,
      BreadcrumbTitle : ""
    };
  },
  computed:{
    isOperator(){
      let result = null;
      if(this.userRole==='operator'){
        result = true
      }else{
         result = false
      }
      return result
    },
  },
  methods: {
    tableHeaderStyle ({row, column, rowIndex, columnIndex}) {
 	    return 'background-color:#ffffee !important ;font-weight:500;' 	
    },
    toggleDialog() {
      this.openPopUp = !this.openPopUp;
    },
    routerPush(formType) {
      let manageAllSelection = {
        searchNews:[],
        searChsan:[],
        searchConstr:[],
        searchLabor:[],
        searchEnviroment:[]
      }
      if(this.typeNews==='searChsan'){
        manageAllSelection.searChsan.push(this.SanList[0])
      }else{
        manageAllSelection[this.typeNews].push(this.tableData[0])
      }
      console.log(manageAllSelection);
      this.$router.push({
        name: "warningListAdd",
        query: {
          caseStatus:'add',
          warningFormType: formType,//people或 org
          typeNews: this.typeNews,
          DataID:JSON.stringify(manageAllSelection),
          routerName:'tableDetailNews'
        }
      });
    },
    closeDetail() {
      this.$confirm("確定要關閉此頁面嗎？", "關閉提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        window.close();
      });
    },
    getList() {
      this.$store.dispatch("loadingHandler", true);

      if( this.typeNews=="searChsan")
        this.BreadcrumbTitle = "聯合國制裁名單明細頁"
      if( this.typeNews=="searchConstr")
        this.BreadcrumbTitle = "政府拒絕往來廠商名單明細頁"        
      if( this.typeNews=="searchLabor")
        this.BreadcrumbTitle = "違反勞動法名單明細頁"
      if( this.typeNews=="searchEnviroment")
        this.BreadcrumbTitle = "環保署裁罰處分名單明細頁"             

      const listQuery = {
        DataID: [this.dataId],
        Page_Size: 1,
        Page_Num: 1,
        IsUser:true
      };
      this.$api.data[this.typeNews](listQuery).then((res)=>{
        if(this.typeNews==='searChsan'){
          this.SanList.push(res.data[0].SanList);
          this.tableData = res.data[0];
        }else{
          this.tableData = res.data
        }
        this.$store.dispatch("loadingHandler", false);
      })
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.tableHead {
  width: 100px;
  background-color: #ffffee;
}

.reminder3box {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.reminder3css {
  width: 90%;
  
  // border: gray solid 1px;
}
.reminder3css > tbody > tr > th {
  width:10%;
  border: gray solid 1px;
  padding: 8px;
}
.reminder3css > tbody > tr > td {
  width:15%;
  border: gray solid 1px;
  padding: 8px;
}


.tableDetailNews {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &__content {
    padding: 20px;
    &--SanList {
      margin-top: 20px;
      table {
        margin-top: 4px;
        border-collapse: collapse;
        width: 100%;
      }
      td,
      th {
        border: 1px solid #000;
        text-align: left;
        padding: 8px;
      }
    }
    .mt-8 {
      margin: 20px 20px;
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 20px 0;
  }
  .WarningListPopUp {
    width: 100%;
    height: 100vh;
    background-color: rgba(133, 133, 133, 0.7);
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .popup {
      position: relative;
      text-align: center;
      width: 20%;
      padding: 5%;
      background-color: white;
      border-radius: 10px;
      .el-icon-close{
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}
</style>
